
// import '../sass/app.scss'
import './bootstrap';



import toastr from 'toastr';
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
// import 'swiper/css/bundle';
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
// import DataTable from 'datatables.net-dt';
import * as THREE from 'three';

window.THREE = THREE;
// window.DataTable = DataTable;
window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.interactionPlugin = interactionPlugin;

window.Swiper = Swiper;
window.toastr = toastr;

// import JSZip from 'jszip';
// window.JSZip = JSZip;

// import DataTable from 'datatables.net-bs5';
// import 'datatables.net-autofill-bs5';
// import 'datatables.net-buttons-bs5';
// import 'datatables.net-buttons/js/buttons.colVis.mjs';
// import 'datatables.net-buttons/js/buttons.print.mjs';
// import 'datatables.net-colreorder-bs5';
// import 'datatables.net-fixedcolumns-bs5';
// import 'datatables.net-fixedheader-bs5';
// import 'datatables.net-keytable-bs5';
// import 'datatables.net-responsive-bs5';
// import 'datatables.net-rowgroup-bs5';
// import 'datatables.net-rowreorder-bs5';
// import 'datatables.net-scroller-bs5';
// import 'datatables.net-searchbuilder-bs5';
// import 'datatables.net-searchpanes-bs5';
// import 'datatables.net-select-bs5';
// import 'datatables.net-staterestore-bs5';
// import 'datatables.net-buttons/js/buttons.html5.mjs';
// window.Datable = DataTable;

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js').then(function (registration) {
        registration.update(); // Forza l'aggiornamento del SW
        // console.log(`SW registered successfully!`);

    }).catch(function (registrationError) {
        console.log(`SW registration failed`);
    });
}


// Public Key:
// BNQdtHMCLWgEGd4uqoS6-HouiIQgazd6S_JKSAaeWZQ2sSZsGaNBH4LoWd5O4oCv3hmI2JCFhz8JtEL87VuYnSk

// Private Key:
// PjxY8hPGAW3MtXHE5f8aFkTtVluW_wEP3Y0t66UraLI
